<template>
  <div>
    <el-row :gutter="25">
      <el-col :span="24">
        <el-card shadow="hover" style="height: 240px">
          <template #header>
            <div class="clearfix" style="font-size: larger; font-weight: bold">
              <span>钢卷号分配</span>
            </div>
          </template>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-row>
              <el-col :span="6">
                <el-form-item
                    label="批次号 :"
                    prop="batchNo"
                    :rules="rules.batchNo"
                >
                  <el-select v-model="ruleForm.batchNo" style="width: 100%" placeholder="请上传之后选择批次号">
                    <el-option
                        v-for="item in batchNoOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :offset="1" :span="5">
                <el-button @click="importData()" type="primary" icon="el-icon-upload">上传</el-button>
                <el-dialog
                    :title="upload.title"
                    :visible.sync="upload.open"
                    width="400px"
                    append-to-body
                >
                  <el-upload
                      ref="upload"
                      :limit="1"
                      accept=".xlsx, .xls"
                      :headers="upload.headers"
                      :action="upload.url"
                      :disabled="upload.isUploading"
                      :on-progress="handleFileUploadProgress"
                      :on-success="handleFileSuccess"
                      :auto-upload="false"
                      :on-error="handleFileError"
                      :before-upload="handleBeforeUpload"
                      :on-change="handChange"
                      :on-remove="handRemove"
                      drag
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      将文件拖到此处，或
                      <em>点击上传</em>
                    </div>
                    <div class="el-upload__tip text-center" slot="tip">
                      <span>仅允许导入xls、xlsx格式文件。</span>
                      <el-link
                          type="primary"
                          :underline="false"
                          style="font-size: 12px; vertical-align: baseline"
                          @click="importTemplate"
                      >下载模板
                      </el-link
                      >
                    </div>
                  </el-upload>
                  <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitFileForm">确 定</el-button>
                    <el-button @click="upload.open = false">取 消</el-button>
                  </div>
                </el-dialog>
              </el-col>
            </el-row>
            <el-row style="margin-top: 10px">
              <el-col :span="3">
                <el-form-item
                    label="最大重量:"
                    prop="weight"
                    :rules="rules.weight"
                >
                  <el-input style="width: 100%" v-model="ruleForm.weight" type="number"
                            placeholder="请输入最大重量"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3">
                <el-form-item
                    label="箱量:"
                    prop="boxNo"
                    :rules="rules.boxNo"
                >
                  <el-input v-model="ruleForm.boxNo" type="number" placeholder="请输入箱量"></el-input>
                </el-form-item>
              </el-col>
              <el-col :offset="1" :span="2">
                <el-button @click="intellectDivide('ruleForm')" type="primary" icon="el-icon-receiving">智能分配
                </el-button>
              </el-col>
              <el-col :offset="0" :span="2">
                <el-button @click="intellectDivide1('ruleForm')" type="primary" icon="el-icon-receiving">贪婪算法
                </el-button>
              </el-col>
              <el-col :span="2">
                <el-button @click="exportDivide()" type="primary" icon="el-icon-download">导出</el-button>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-card shadow="hover" style="height: 725px; overflow: auto;">
        <template #header>
          <div class="clearfix" style="font-size: larger; font-weight: bold">
            <span>实时动态</span>
          </div>
        </template>
        <el-table :data="this.tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header">
          <el-table-column prop="batchNo" label="批次号" align="center">
          </el-table-column>
          <el-table-column prop="schemeNo" label="方案" align="center"></el-table-column>
          <el-table-column prop="weight" label="重量" align="center"></el-table-column>
          <el-table-column prop="amount" label="数量" align="center"></el-table-column>
          <el-table-column prop="remake1" label="描述" align="center"></el-table-column>
        </el-table>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import {importExcel} from '@/api/steelNoJzx'
import {getBatchNo} from '@/api/steelNoJzx'
import {intellectDivide} from '@/api/steelNoJzx'
import {intellectDivide1} from '@/api/steelNoJzx'
import {getBatchStatus} from '@/api/steelNoJzx'
import {updateOldSchemeNo} from '@/api/steelNoJzx'
import {getToken} from '@/utils/auth'
import {Loading} from 'element-ui';
import {Toast} from "vant";

export default {
  name: 'steelNoJzx',
  components: {importExcel, getToken, Loading},
  data() {
    return {
      upload: {
        // 是否显示弹出层
        open: false,
        // 是否禁用上传
        isUploading: false,
        title: '',
        // 设置上传的请求头部
        headers: {
          Authorization: 'Bearer ' + getToken()
        },
        // 上传的地址，自己设置
        url: '/api/ajax/steelNoJzx/importExcel.json'
      },
      batchNo: '',
      batchNoOptions: [],
      options: {
        fullscreen: false
      },
      loadingInstance: null,
      weight: '',
      boxNo: '',
      ruleForm: {
        batchNo: '',
        weight: '',
        boxNo: ''
      },
      rules: {
        batchNo: [
          {required: true, message: '请选择批次号', trigger: 'blur'},
        ],
        weight: [
          {required: true, message: '请填写最大重量', trigger: 'blur'}
        ],
        boxNo: [
          {required: true, message: '请填写箱量', trigger: 'blur'}
        ],
      },
      tableData: [],
    }
  },
  mounted() {
    Toast.fail('建议在PC端操作！');
    this.getBatchNo()
    let timer = setInterval(() => {
      if (this.ruleForm.batchNo != '') {
        getBatchStatus({
          batchNo: this.ruleForm.batchNo
        }).then(res => {
          if (res.length == 0) {

          } else {
            this.tableData = res
          }
        })
      }
      //需要定时执行的代码
    }, 5000)
  },
  methods: {
    getBatchNo() {
      this.batchNoOptions = []
      getBatchNo({
        batchNo: ''
      }).then(res => {
        console.log(res)
        for (var i = 0; i < res.length; i++) {
          let lt = {}
          lt.value = res[i].batchNo
          lt.label = res[i].batchNo
          this.batchNoOptions.push(lt)
        }
        console.log(this.batchNoOptions)
      })
    },
    importData() {
      this.upload.open = true
    },
    importTemplate() {
      this.download('/api/ajax/steelNoJzx/exportExcel.json', {}, `钢卷号分配.xlsx`)
    },
    /** 导入按钮操作 */
    handleImport() {
      this.upload.title = '文件上传'
      this.upload.open = true
    },
    //文件上传之前
    handleBeforeUpload(file) {
      // 限制文件上传大小
      const limit = file.size / 1024 / 1024 < 5
      if (!limit) {
        this.$message.error('上传文件大小不能超过 5MB!')
        this.upload.isUploading = false
        return false
      }
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true
    },
    //文件上传错误
    handleFileError() {
      this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        this.loadingInstance.close();
      });
      this.$message.error('文件上传失败')
    },
    //文件变化
    handChange(file, fileList) {
      console.log('文件改变')
    },
    //删除移除
    handRemove(file, fileList) {
      console.log('文件移除')
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false
      this.upload.isUploading = false
      this.$refs.upload.clearFiles()
      this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        this.loadingInstance.close();
      });
      this.$alert('<div style=\'overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;\'>' + response.msg + '</div>', '导入结果', {dangerouslyUseHTMLString: true})
      this.getBatchNo()
    },
    // 提交上传文件
    submitFileForm() {
      console.log(this.upload)
      console.log(this.$refs.upload.uploadFiles)
      if (this.$refs.upload.uploadFiles.length > 0) {
        this.loadingInstance = Loading.service(this.options);
        this.$refs.upload.submit()

      } else {
        this.$message.info('请选择文件!')
      }
    },
    uploadFile(item) {
      console.log(item)
    },
    intellectDivide(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({
            target: '.main',
            background: 'raga(0,0,0,0.5)',
            text: '智能分配中，请勿重复操作！'
          });
          updateOldSchemeNo({
            batchNo: this.ruleForm.batchNo,
          }).then(res => {
            console.log(res)
          })
          intellectDivide({
            batchNo: this.ruleForm.batchNo,
            boxNo: this.ruleForm.boxNo,
            weight: this.ruleForm.weight,
            createdPeople: this.$store.getters.userId
          }).then(res => {
            console.log(res)
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
              this.loadingInstance.close();
            });
            switch (res) {
              case 200:
                this.$message({
                  type: 'success',
                  message: "分配完成！"
                });
                getBatchStatus({
                  batchNo: this.ruleForm.batchNo
                }).then(res => {
                  if (res.length == 0) {

                  } else {
                    this.tableData = res
                  }
                })
                break;
              case 201:
                this.$message({
                  type: 'info',
                  message: "队列中"
                });
                break;
              case 202:
                this.$message({
                  type: 'info',
                  message: "执行中，请等待推送！"
                });
                break;
              case 800:
                this.$message.error("异常!");
                break;
              case 801:
                this.$message({
                  type: 'warning',
                  message: "未查询到记录!"
                });
                break;
            }
          }).catch(res => {
            console.log(res)
            this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
              this.loadingInstance.close();
            });
            this.$message({
              type: 'warning',
              message: '分配失败！'
            });
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    intellectDivide1(formName) {
      updateOldSchemeNo({
        batchNo: this.ruleForm.batchNo,
      }).then(res => {
        console.log(res)
      })
      if(this.ruleForm.batchNo.length==0 || this.ruleForm.weight.length==0){
        this.$message({
          type: 'info',
          message: '请补全批次号和最大重量！'
        });
      }else{
        intellectDivide1({
          batchNo: this.ruleForm.batchNo,
          weight: this.ruleForm.weight,
          createdPeople: this.$store.getters.userId
        }).then(res => {
          console.log(res)
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close();
          });
          switch (res) {
            case 200:
              this.$message({
                type: 'success',
                message: "分配完成！"
              });
              getBatchStatus({
                batchNo: this.ruleForm.batchNo
              }).then(res => {
                if (res.length == 0) {

                } else {
                  this.tableData = res
                }
              })
              break;
            case 201:
              this.$message({
                type: 'info',
                message: "队列中"
              });
              break;
            case 202:
              this.$message({
                type: 'info',
                message: "执行中，请等待推送！"
              });
              break;
            case 800:
              this.$message.error("异常!");
              break;
            case 801:
              this.$message({
                type: 'warning',
                message: "未查询到记录!"
              });
              break;
          }
        }).catch(res => {
          console.log(res)
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            this.loadingInstance.close();
          });
          this.$message({
            type: 'warning',
            message: '分配失败！'
          });
        })
      }
    },
    exportDivide() {
      if (this.ruleForm.batchNo.length == 0) {
        this.$message({
          type: 'info',
          message: '请选择批次号'
        });
      } else {
        console.log(this.ruleForm.batchNo)
        getBatchStatus({
          batchNo: this.ruleForm.batchNo
        }).then(res => {
          console.log(res)
          if (res.length == 0) {
            this.$confirm('此文件尚未智能分配，是否继续下载?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.download('/api/ajax/steelNoJzx/exportDivide.json', {batchNo: this.ruleForm.batchNo}, `钢卷号分配.xlsx`)
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消下载'
              });
            });
          } else {
            console.log(res[0])
            if (res[0].schemeNo == null) {
              this.$confirm('此文件尚未分配完毕，是否继续下载?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.download('/api/ajax/steelNoJzx/exportDivide.json', {batchNo: this.ruleForm.batchNo}, `钢卷号分配.xlsx`)
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消下载'
                });
              });
            } else {
              this.download('/api/ajax/steelNoJzx/exportDivide.json', {batchNo: this.ruleForm.batchNo}, `钢卷号分配.xlsx`)
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
