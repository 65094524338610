import request from '@/utils/request'

export function importExcel(formFile) {
  return request({
    url: "/api/ajax/steelNoJzx/importExcel.json",
    method: 'post',
    data: formFile,
    enctype: 'multipart/form-data'
  })
}

export function getBatchNo(formFile) {
  return request({
    url: "/api/ajax/steelNoJzx/getBatchNo.json",
    method: 'post',
    data: formFile,
  })
}

export function intellectDivide(formFile) {
  return request({
    url: "/api/ajax/steelNoJzx/orderCplex.json",
    method: 'post',
    data: formFile,
  })
}

export function intellectDivide1(formFile) {
  return request({
    url: "/api/ajax/steelNoJzx/orderCplex1.json",
    method: 'post',
    data: formFile,
  })
}

export function intellectDivideStatus(formFile) {
  return request({
    url: "/service/landtran/orderStatus/" + formFile,
    method: 'get',
  })
}

export function getBatchStatus(formFile) {
  return request({
    url: "/api/ajax/steelNoJzx/getBatchStatus.json",
    method: 'post',
    data: formFile,
  })
}

export function updateOldSchemeNo(formFile) {
  return request({
    url: "/api/ajax/steelNoJzx/updateOldSchemeNo.json",
    method: 'post',
    data: formFile,
  })
}




